import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week starts today!`}</em></p>
    <p>{`Skill Practice: Kipping Pullups`}</p>
    <p>{`then,`}</p>
    <p><em parentName="p">{`(Murph Prep)`}</em></p>
    <p>{`1000M Row`}</p>
    <p>{`50 Pullups`}</p>
    <p>{`100 Pushups`}</p>
    <p>{`200 Squats`}</p>
    <p>{`1000M Row`}</p>
    <p>{`For time. `}<em parentName="p">{`(Partition the pullups, pushups and squats any way you’d
like)`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      